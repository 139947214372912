export const Translations = {
    BG: 'bulgarian',
    CZ: 'czech',
    DE: 'deutch',
    US: 'english',
    FI: 'finnish',
    FR: 'french',
    GR: 'greek',
    HR: 'croatian',
    IT: 'italian',
    LT: 'lithuanian',
    PT: 'portuguese',
    RO: 'romanian',
    SP: 'spanish',
    TR: 'turkish',
};

export const LocaleTranslations = {
    pt: 'PT',
    es: 'SP',
    sp: 'SP',
    cs: 'CZ',
    cz: 'CZ',
    fi: 'FI',
    ro: 'RO',
    bg: 'BG',
    it: 'IT',
    hr: 'HR',
    lt: 'LT',
    de: 'DE',
    fr: 'FR',
    en: 'US',
    us: 'US',
    el: 'GR',
    gr: 'GR',
    tr: 'TR',
};

export const lang = {
    BG: 'bg',
    CZ: 'cs',
    DE: 'de',
    US: 'en',
    FI: 'fj',
    FR: 'fr',
    GR: 'el',
    HR: 'hr',
    IT: 'it',
    LT: 'lt',
    PT: 'pt',
    RO: 'ro',
    SP: 'es',
    TR: 'tr',
};

export const IconTypes = {
    ERROR: 'error',
    WARNING: 'warning',
    SUCCESS: 'success',
};

export const NewsEventsOptions = {
    all: 'NewsEvents.ButtonAll',
    news: 'NewsEvents.ButtonNews',
    events: 'NewsEvents.ButtonEvents',
};

export const Roles = {
    student: 'Student',
    teacher: 'Teacher/Educator/Tutor',
    training: 'Teacher in Training',
    professional: 'Film Professional',
    researcher: 'Researcher/University Student',
    other: 'Other',
};

const WINDOWS_PLAYER = '/player/CINED-2.0.0.exe';
const MAC_PLAYER = '/player/CINED-2.0.0.dmg';
const DEEPLINK_URI = 'com-cined-play://home';

export const getTranslation = (content, lng, prefix, defaultTranslation) => {
    const language = prefix + Translations[lng];
    const lngTranslation = content?.Translations.find((x) => x.__component === language);
    const enTranslation = content?.Translations.find((x) => x.__component === defaultTranslation);

    const translation = lngTranslation ? lngTranslation : enTranslation;
    return translation;
};

export const getMovieURLSegment = (platformLink) => {
    const lastSegment = platformLink?.substring(platformLink.lastIndexOf('/') + 1);

    /*let link = originalTitle.toLowerCase().replace(/[ /']/g, '-');
    link = link.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    const normalizedLink = originalTitle.toLowerCase().trim();

    if (normalizedLink === MOVIE_LINK.original.toLowerCase().trim()) {
        link = 'shelter';
    }*/

    return lastSegment;
};

export const getLanguageInitials = (language) => {
    let languageInitials = 'en';
    switch (language) {
        case 'PT':
        case 'FR':
        case 'IT':
        case 'RO':
        case 'FI':
        case 'BG':
        case 'GR':
        case 'TR':
            languageInitials = language.toLowerCase();
            break;
        case 'SP':
            languageInitials = 'es';
            break;
        case 'CZ':
            languageInitials = 'cs';
            break;
        case 'LT':
            languageInitials = 'lt';
            break;
        default:
            languageInitials = 'en';
            break;
    }

    return languageInitials;
};

export const toDataURL = (url, callback) => {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
        let reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
};

export const countLines = (divId) => {
    const el = document.getElementById(divId);
    if (!el) {
        return 0;
    }
    const prevLH = el.style.lineHeight;
    const factor = 1000;
    el.style.lineHeight = factor + 'px';

    const height = el.getBoundingClientRect().height;
    el.style.lineHeight = prevLH;

    return Math.floor(height / factor);
};

export const getOS = () => {
    const userAgent = window.navigator.userAgent,
        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (macosPlatforms.indexOf(platform) !== -1 || platform.toLowerCase().indexOf('mac') !== -1) {
        os = 'Mac OS';
    } else if (windowsPlatforms.indexOf(platform) !== -1 || platform.toLowerCase().indexOf('win') !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (/Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
};

export const getMovieImageURL = (movie, photoKey) => {
    const url = movie?.[photoKey]?.url;
    const thumbnail = movie?.[photoKey]?.formats?.medium?.url;
    return thumbnail ? thumbnail : url;
};

export const getPlayer = () => {
    const os = getOS();
    let deeplink = DEEPLINK_URI;
    let player = '';
    let isSupported = true;

    switch (os) {
        case 'Windows':
            player = WINDOWS_PLAYER;
            break;
        case 'Mac OS':
            player = MAC_PLAYER;
            break;
        default:
            isSupported = false;
    }

    return {
        support: isSupported,
        player: player,
        deeplink: deeplink,
    };
};
